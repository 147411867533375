import React, { useRef, useEffect, useState } from 'react';
import Network from './Requests';
import { useSelector, useDispatch } from 'react-redux';
import {
  setListProduct,
  setCurrentProduct,
  clearList,
  setSave,
  setInfoProduct,
} from '../store/reducers/productSlice';
import useConfirmationDialog from '../hooks/useConfirmationDialog';
import useInput from '../hooks/useInput';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import { DataGrid } from '@material-ui/data-grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';

export default function Product(props) {
  const dispatch = useDispatch();
  const storeCurrent = useSelector((state) => state.store.current);
  const productList = useSelector((state) => state.product.list);
  const productCurrent = useSelector((state) => state.product.current);
  const productCurrentInfo = useSelector((state) => state.product.info);
  const filterNameProduct = useInput();
  const [directionName, setDirectionName] = useState('CreatedAt');
  const [direction, setDirection] = useState(2);
  const { Dialog, onOpen } = useConfirmationDialog({
    headerText: 'Удаление',
    bodyText: 'Вы уверены что хотите удалить запись?',
    confirmationButtonText: 'Да',
    onConfirmClick: () => {
      delItem(payloadRef.current.idDel);
      onOpen(false);
    },
  });
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const columns = [
    { field: 'name', headerName: 'Название', width: 90 },
    { field: 'dateCreate', headerName: 'Дата создания', width: 90 },
    { field: 'dateUpdate', headerName: 'Дата редактир...', width: 90 },
  ];

  const sorting = [
    {
      nameField: 'Name',
      name: 'Название',
    },
    {
      nameField: 'CreatedAt',
      name: 'Дата создания',
    },
    {
      nameField: 'UpdatedAt',
      name: 'Дата редактир..',
    },
  ];

  let payloadRef = useRef({
    idDel: null,
  });

  const handleDeleteClick = (id) => {
    payloadRef.current.idDel = id;
    onOpen(true);
  };

  const changeCurrentProduct = (token) => {
    dispatch(setSave(false));
    dispatch(setCurrentProduct(token));
  };

  const clear = () => {
    const fetch = async () => {
      const product = await new Network().GetByStore(storeCurrent?.id, false);

      if (product?.length > 0) {
        dispatch(clearList());
        dispatch(setSave(true));
        dispatch(setListProduct(product));
        dispatch(
          setInfoProduct({
            allowQuickLook: true,
            allowAppClip: true,
            startingWithAutoPlaneDetection: true,
          }),
        );
      }
    };
    fetch();
  };

  useEffect(() => {
    const fetch = async () => {
      const product = await new Network().GetByStorePost(
        storeCurrent?.id,
        false,
        directionName,
        direction,
      );

      if (product?.length > 0) {
        dispatch(setListProduct(product));
        dispatch(setSave(false));
        dispatch(setCurrentProduct(product[0]?.token));
      } else {
        dispatch(setSave(true));
        dispatch(clearList());
      }
    };
    fetch();
  }, [storeCurrent]);

  const delItem = async (token) => {
    let insert = JSON.parse(JSON.stringify(productCurrentInfo));
    insert.isArchived = true;

    Object.preventExtensions(insert);

    const send = await new Network().DeleteProd(token);
    let prod = productList.filter((o) => o?.token != token);
    dispatch(setListProduct(prod));
  };

  const sort = async (field) => {
    let s;
    let d;
    if (directionName == field) {
      s = field;
      if (direction == 1) {
        d = 2;
      } else if (direction == 2) {
        d = 1;
      }
    } else {
      s = field;
      if (direction == 1) {
        d = 2;
      } else if (direction == 2) {
        d = 1;
      }
    }
    setDirectionName(s);
    setDirection(d);
    const product = await new Network().GetByStorePost(storeCurrent?.id, false, s, d);
    if (product?.length > 0) {
      dispatch(setListProduct(product));
      dispatch(setCurrentProduct(product[0]?.token));
    }
  };
  const classes = useStyles();

  const styleBorder = (position, type) => {
    if (position == 'left' && type == 1) {
      return {
        borderLeft: '2px solid green',
        borderTop: '2px solid green',
        borderBottom: '2px solid green',
      };
    } else if (position == 'left' && type == 2) {
      return {
        borderLeft: '2px solid red',
        borderTop: '2px solid red',
        borderBottom: '2px solid red',
      };
    }

    if (position == 'center' && type == 1) {
      return { borderTop: '2px solid green', borderBottom: '2px solid green' };
    } else if (position == 'center' && type == 2) {
      return { borderTop: '2px solid red', borderBottom: '2px solid red' };
    }

    if (position == 'right' && type == 1) {
      return {
        borderRight: '2px solid green',
        borderTop: '2px solid green',
        borderBottom: '2px solid green',
      };
    } else if (position == 'right' && type == 2) {
      return {
        borderRight: '2px solid red',
        borderTop: '2px solid red',
        borderBottom: '2px solid red',
      };
    }
  };

  return (
    <div className="wrap productBlock">
      <Dialog />
      <div className="product">
        <div className="product--name">Товары</div>
        <div className="product--add" onClick={clear}>
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ControlPointIcon">
            <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </svg>
        </div>
      </div>
      <div className="product-list">
        <div className="info-inputs">
          <input type="text" placeholder="Поиск по имени" {...filterNameProduct} />
        </div>
        {productList ? (
          <>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {sorting.map((i) => {
                      return (
                        <TableCell>
                          <div
                            className="sorting"
                            key={i.nameField}
                            onClick={() => sort(i.nameField)}>
                            <span className={direction == 1 ? 'desc' : 'asc'}>
                              {directionName == i.nameField && <ArrowUpwardIcon />}
                            </span>
                            <span>{i.name}</span>
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productList
                    .filter((item) => {
                      return item?.name
                        .toLowerCase()
                        .includes(filterNameProduct.value.toLowerCase());
                    })
                    .map((row) => (
                      <TableRow
                        key={row?.token}
                        className={row.token == productCurrent ? 'active' : ''}
                        cellPadding="3"
                        onClick={(e) => changeCurrentProduct(row.token)}>
                        <TableCell style={styleBorder('left', row.autoTestStatus)}>
                          <div className="row-div">
                            <div
                              className="product-img"
                              style={{
                                backgroundImage: `url("${row.iconPath !== null && row?.iconPath}`,
                              }}></div>
                            <span>{row.name}</span>
                          </div>
                        </TableCell>
                        <TableCell style={styleBorder('center', row.autoTestStatus)}>
                          {row.createdAt}
                        </TableCell>
                        <TableCell style={styleBorder('center', row.autoTestStatus)}>
                          {row.updatedAt}
                        </TableCell>
                        <TableCell style={styleBorder('right', row.autoTestStatus)}>
                          <div className="del-item" onClick={() => handleDeleteClick(row.token)}>
                            <svg
                              className="MuiSvgIcon-root jss139"
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              title="fontSize medium">
                              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                            </svg>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          'Товаров нет'
        )}
      </div>
    </div>
  );
}
