import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenChange, setIcon, setStore, setCurrentStore } from '../store/reducers/storeSlice';
import Button from '@material-ui/core/Button';
import Network from './Requests';

export default function Dropzone() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [rejected, setRejected] = useState([]);
  const open = useSelector((state) => state.store.openChangeIcon);
  const currentStore = useSelector((state) => state.store.current.id);
  const companyCurrent = useSelector((state) => state.company.current);
  const iconPath = useSelector((state) => state.store.icon);

  const handleClose = () => {
    dispatch(setIcon(''));
    dispatch(setOpenChange(false));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowX: 'hidden',
    overflowY: 'scroll',
  };

  useEffect(() => {
    if (iconPath?.length > 0) {
      setFiles([{ preview: iconPath }]);
    } else {
      setFiles([]);
    }
  }, [iconPath]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length) {
      setFiles((previousFiles) => [
        ...files,
        ...acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })),
      ]);
    }
    console.log(files, 'filess');
    if (rejectedFiles?.length) {
      setRejected((previousFiles) => [...previousFiles, ...rejectedFiles]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxSize: 6000 * 6000,
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const removeFile = (name) => {
    setFiles((files) => files.filter((file) => file.name !== name));
  };

  const removeAll = () => {
    setFiles([]);
    setRejected([]);
  };

  const removeRejected = (name) => {
    setRejected((files) => files.filter(({ file }) => file.name !== name));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (!files?.length) {
      files.forEach((file) => formData.append('icon', null));
      const send = await new Network().changeIcon(formData, currentStore);
    } else {
      if (typeof files[0]?.path === 'undefined') {
        handleClose();
      } else {
        files.forEach((file) => formData.append('icon', file));
        const send = await new Network().changeIcon(formData, currentStore);
      }
    }
    const store = await new Network().GetStoresByCompany(companyCurrent);

    dispatch(setStore(store));
    dispatch(setCurrentStore({ id: store[0]?.id, guid: store[0]?.guid }));
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="storeProduct-container">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            <div className="storeProduct--title">
              <span>Иконка стора</span>
            </div>
          </Typography>
          {/* <form onSubmit={handleSubmit}> */}
          <div {...getRootProps({})}>
            <input {...getInputProps()} />
            <div className="dropzone">
              {isDragActive ? (
                <p>Кидайте файлы сюда...</p>
              ) : (
                <p>Перетащите файл сюда или щелкните, чтобы выбрать.</p>
              )}
            </div>
          </div>
          <section className="mt-10">
            {files.map((file) => (
              <div key={file.name} className="relative h-32 rounded-md shadow-lg view-icon">
                <img
                  src={file.preview}
                  alt={file.name}
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
                <div
                  type="button"
                  className="icon-remove"
                  onClick={() => removeFile(file.name)}></div>
              </div>
            ))}
            <div className="info-button">
              <Button className="info-save" variant="contained" onClick={handleSubmit}>
                Сохранить
              </Button>
              <Button className="info-save" variant="contained" onClick={handleClose}>
                Отменить
              </Button>
            </div>
          </section>
          {/* </form> */}
        </Box>
      </div>
    </Modal>
  );
}
