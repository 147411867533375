import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  update: false,
  item: {},
};

export const storeSlice = createSlice({
  name: 'storeAdd',
  initialState,
  reducers: {
    setOpenAdd: (state, action) => {
      state.open = action.payload;
    },
    setSave: (state, action) => {
      state.update = action.payload;
    },
    setItem: (state, action) => {
      state.item = action.payload;
    },
  },
});

export const { setOpenAdd, setSave, setItem } = storeSlice.actions;

export default storeSlice.reducer;
