import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  text: '',
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setOpenError: (state, action) => {
      state.open = action.payload;
    },
    setTextError: (state, action) => {
      state.text = action.payload;
    },
  },
});

export const { setOpenError, setTextError } = errorSlice.actions;

export default errorSlice.reducer;
