import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

const initialState = {
  companies: [],
  current: '',
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setListCompany: (state, action) => {
      state.companies = action.payload;
    },
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { setListCompany, setCurrent } = companySlice.actions;

export default companySlice.reducer;
