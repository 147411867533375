/* eslint-disable no-unused-expressions */
import React, { useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

export const useConfirm = (title, message) => {
  const [isOpen, setStatusOpen] = useState(false);
  const [state, setState] = useState({
    resolve: null,
  });
  const config = {
    open: () => {
      setStatusOpen(true);
    },
    openByPromise: () =>
      new Promise((resolve, reject) => {
        setStatusOpen(true);
        setState({ resolve });
      }),
  };

  const handleClose = () => {
    setStatusOpen(false);
  };

  const ConfirmationDialog = useMemo(() => {
    const handleConfirm = () => {
      state?.resolve && state?.resolve(true);
      handleClose();
    };
    const handleCancel = () => {
      state?.resolve && state?.resolve(false);
      handleClose();
    };

    return () => (
      <Dialog open={isOpen} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="info-save" variant="contained" onClick={handleConfirm}>
            Да
          </Button>
          <Button className="info-save" variant="contained" onClick={handleCancel}>
            Нет
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [isOpen, title, message, state.resolve]);

  return [ConfirmationDialog, config];
};
