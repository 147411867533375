import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  current: { id: '', guid: '', icon: '' },
  openAdd: false,
  openChangeIcon: false,
  icon: {},
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.list = action.payload;
    },
    setCurrentStore: (state, action) => {
      state.current = action.payload;
    },
    setOpenAdd: (state, action) => {
      state.openAdd = action.payload;
    },
    setOpenChange: (state, action) => {
      state.openChangeIcon = action.payload;
    },
    setIcon: (state, action) => {
      state.icon = action.payload;
    },
  },
});

export const { setStore, setCurrentStore, setOpenAdd, setOpenChange, setIcon } = storeSlice.actions;

export default storeSlice.reducer;
