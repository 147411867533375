import React, { useState, useEffect } from 'react';
import Network from './Requests';
import { useSelector, useDispatch } from 'react-redux';
import {
  setOpenAdd,
  setItem,
  setSave,
  setStore,
  setCurrentStore,
} from '../store/reducers/storeSlice';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import useInput from '../hooks/useInput';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';

export default function ModalStore(props) {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company.current);
  const open = useSelector((state) => state.store.openAdd);
  const storeList = useSelector((state) => state.store.list);
  const domain = useInput('');
  const [openSnack, setOpenSnack] = React.useState();
  const [errortext, setText] = React.useState('');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowX: 'hidden',
    overflowY: 'scroll',
    // height: '95%',
  };

  const handleClose = () => {
    dispatch(setOpenAdd(false));
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const save = async () => {
    let add = await new Network().storeAdd(domain?.value, company);
    if (add?.status == 'error') {
      setText(add.text);
      setOpenSnack(true);
    } else {
      handleClose();
      domain.onChange('');
      dispatch(setCurrentStore({ id: add?.id, guid: add?.guid }));
      dispatch(setStore([add, ...storeList]));
    }
  };

  useEffect(() => {
    const fetch = async () => {};
    fetch();
  }, []);

  return (
    <>
      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={7000}
        onClose={handleCloseSnack}>
        <div className="messageAl">
          <div className="messageAl--text">
            <span>
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="ErrorOutlineIcon">
                <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
              </svg>{' '}
            </span>
            <span>{errortext}</span>
          </div>
          <CloseIcon fontSize="small" onClick={handleClose} />
        </div>
      </Snackbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className="storeProduct-container">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              <div className="storeProduct--title">
                <span>Добавить домен</span>
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div class="info-row info-row--sm">
                <div class="info-name">Домен</div>
                <div class="info-inputs">
                  <input type="text" placeholder="Домен" {...domain} />
                </div>
              </div>
              <div className="info-button">
                <Button className="info-save" variant="contained" onClick={save}>
                  Сохранить
                </Button>
                <Button className="info-save" variant="contained" onClick={handleClose}>
                  Отменить
                </Button>
              </div>
            </Typography>
          </Box>
        </div>
      </Modal>
    </>
  );
}
