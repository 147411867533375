import React from 'react';
import Store from '../../Components/Store';
import Product from '../../Components/Product';
import ProductInfo from '../../Components/ProductInfo';
import { useSelector } from 'react-redux';
import ModalStoreAdd from '../../Components/ModalStoreAdd';
import ModalDomainAdd from '../../Components/ModalDomainAdd';
import ModalCopyProduct from '../../Components/ModalCopyProduct';
import { Redirect } from 'react-router';
import { useEffect, useState } from 'react';
import Network from '../../Components/Requests';

function Main() {
  const [user, setUser] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const company = useSelector((state) => state?.company?.current);

  useEffect(() => {
    const fetch = async () => {
      const user = await new Network().GetUserGuid();
      if (user?.length > 0) {
        setUser(user);
      } else {
        setRedirect(true);
      }
    };
    fetch();
  }, []);

  return (
    <div className="wrapper-main">
      {redirect && <Redirect to="/" />}
      {user != null && (
        <>
          <div>
            <Store />
          </div>
          <div>
            {company ? (
              <>
                <Product />
                <ModalStoreAdd />
                <ModalDomainAdd />
                <ModalCopyProduct />
              </>
            ) : (
              <div className="errorCompany">У Вас нет доступных компаний</div>
            )}
          </div>
          <div>{company && <ProductInfo />}</div>
        </>
      )}
    </div>
  );
}

export default Main;
