import { useState, useEffect } from 'react';

export default function useInput(initialValue) {
  const [value, setValue] = useState(initialValue ?? ''); // Set to empty string if initialValue is null

  const onChange = (e) => {
    if (e?.target?.value !== undefined) {
      setValue(e.target.value);
    } else if (typeof e === 'boolean') {
      setValue(e);
    } else if (e === null) {
      setValue('');
    } else {
      setValue(e);
    }
  };

  useEffect(() => {
    setValue(initialValue ?? ''); // Reset to initialValue or empty string if null
  }, [initialValue]);

  return {
    value,
    onChange,
  };
}
