import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

const initialState = {
  copy: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setOpenCopy: (state, action) => {
      state.copy = action.payload;
    },
  },
});

export const { setOpenCopy } = modalSlice.actions;

export default modalSlice.reducer;
