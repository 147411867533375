import React, { useState, useEffect } from 'react';
import Network from './Requests';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import { useSelector, useDispatch } from 'react-redux';
import { setProductStore } from '../store/reducers/productStoreSlice';
import { setOpenAdd, setSave, setItem } from '../store/reducers/productStoreAddSlice';
import Button from '@material-ui/core/Button';
import useInput from '../hooks/useInput';
import { ChangeEvent } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

export default function ModalStoreAdd() {
  const dispatch = useDispatch();
  const openMod = useSelector((state) => state.productStoreAdd.open);
  const storeList = useSelector((state) => state.productStore.list);
  const storeItem = useSelector((state) => state.productStoreAdd.item);
  const update = useSelector((state) => state.productStoreAdd.update);
  const [file, setFile] = useState();
  const [errortext, setText] = React.useState('');
  const [open, setOpen] = React.useState();
  const [openSuccess, setOpenSuccess] = React.useState(false);
  let name = useInput(storeItem?.name ? storeItem?.name : '');
  let description = useInput(storeItem?.description ? storeItem?.description : '');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: 'auto',
  };

  const handleClose = () => {
    dispatch(setOpenAdd(false));
  };

  const saveOrUpdate = async () => {
    if (update) {
      var formData = new FormData();
      var imagefile = document.querySelector('#file');

      if (imagefile.files[0]) {
        formData.append('file', imagefile.files[0]);
      }

      formData.append('name', name.value);
      formData.append('id', storeItem?.id);
      formData.append('description', description.value);
      let add = await new Network().UpdateSkybox(formData);
      if (add?.status == 'error') {
        setText(add.text);
        setOpen(true);
      } else {
        dispatch(setProductStore([add, ...storeList]));
        setOpenSuccess(true);
        dispatch(setOpenAdd(false));
        dispatch(setSave(false));
        dispatch(setItem({}));
        name = '';
        description = '';
      }
    } else {
      var formData = new FormData();
      var imagefile = document.querySelector('#file');

      formData.append('file', imagefile.files[0]);
      formData.append('name', name.value);
      formData.append('description', description.value);
      let add = await new Network().AddSkybox(formData);

      if (add?.status == 'error') {
        setText(add.text);
        setOpen(true);
      } else {
        dispatch(setProductStore([add, ...storeList]));
        setOpenSuccess(true);
        dispatch(setOpenAdd(false));
        dispatch(setSave(false));
        dispatch(setItem({}));
        name = '';
        description = '';
      }
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={7000}
        onClose={handleClose}>
        <div className="messageAl">
          <div className="messageAl--text">
            <span>
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="ErrorOutlineIcon">
                <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
              </svg>{' '}
            </span>
            <span>{errortext}</span>
          </div>
          <CloseIcon fontSize="small" onClick={handleClose} />
        </div>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={7000}
        onClose={handleCloseSuccess}>
        <div className="messageAlSuccses">
          <div className="messageAl--text">
            <span>
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="SuccessOutlinedIcon">
                <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path>
              </svg>{' '}
            </span>
            <span>Данные сохранены!!!</span>
          </div>

          <CloseIcon fontSize="small" onClick={handleCloseSuccess} />
        </div>
      </Snackbar>
      <Modal
        open={openMod}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className="storeProduct-container">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              <div className="storeProduct--title">Добавить расчёт</div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div class="info-row info-row--sm">
                <div class="info-name">Имя</div>
                <div class="info-inputs">
                  <input type="text" placeholder="Имя" {...name} />
                </div>
              </div>
              <div class="info-row info-row--sm">
                <div class="info-name">Описание</div>
                <div class="info-inputs">
                  <input type="text" placeholder="Описание" {...description} />
                </div>
              </div>
              <div class="info-row info-row--sm">
                <div class="info-name">Файл</div>
                <div class="info-inputs">
                  <input type="file" id="file" onChange={handleFileChange} />
                  <div className="name-item">{storeItem?.name && `${storeItem?.name}`}</div>
                </div>
              </div>
              <div className="info-button">
                <Button className="info-save" variant="contained" onClick={saveOrUpdate}>
                  Сохранить
                </Button>
              </div>
            </Typography>
          </Box>
        </div>
      </Modal>
    </>
  );
}
