import React, { useState, useEffect } from 'react';
import Network from './Requests';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenCopy } from '../store/reducers/modalSlice';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import useInput from '../hooks/useInput';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function ModalStore(props) {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal.copy);
  const storeList = useSelector((state) => state.store.list);
  const storeCurrent = useSelector((state) => state.store.current);
  const prodInfo = useSelector((state) => state.product.info);

  const [store, setStore] = React.useState({});
  const [filterListStore, setFilterListStore] = React.useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [errortext, setText] = React.useState('');
  const [openSuccess, setOpenSuccess] = React.useState(false);

  let list = [];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowX: 'hidden',
    overflowY: 'scroll',
  };

  useEffect(() => {
    if (open) {
      list = storeList.filter((i) => i.id != storeCurrent?.id);
      setFilterListStore(list);
    }
  }, [open]);

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleClose = () => {
    dispatch(setOpenCopy(false));
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const save = async () => {
    let clone = JSON.parse(JSON.stringify(prodInfo));
    clone.externalUrl = '';
    clone.token = '';
    for (let s = 0; s < store.length; s++) {
      const element = store[s];
      clone.idStore = element;

      let domain = storeList.filter((store) => store.id == element);
      console.log(domain);
      clone.externalUrl = 'https://' + domain[0]?.domain;

      const send = await new Network().addProduct(clone);
      if (send?.status == 'error') {
        setText(send.text);
        setOpenSnack(true);
      } else {
        dispatch(setOpenCopy(false));
        setOpenSuccess(true);
      }
    }
  };

  const changeStore = (e) => {
    const { options } = e.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setStore(value);
  };

  return (
    <>
      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={7000}
        onClose={handleCloseSnack}>
        <div className="messageAl">
          <div className="messageAl--text">
            <span>
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="ErrorOutlineIcon">
                <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
              </svg>{' '}
            </span>
            <span>{errortext}</span>
          </div>
          <CloseIcon fontSize="small" onClick={handleClose} />
        </div>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={7000}
        onClose={handleCloseSuccess}>
        <div className="messageAlSuccses">
          <div className="messageAl--text">
            <span>
              <svg
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="SuccessOutlinedIcon">
                <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"></path>
              </svg>{' '}
            </span>
            <span>Данные сохранены!!!</span>
          </div>

          <CloseIcon fontSize="small" onClick={handleCloseSuccess} />
        </div>
      </Snackbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className="storeProduct-container">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              <div className="storeProduct--title">
                <span>Выберите домен</span>
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div class="info-row info-row--sm copyProd">
                <FormControl>
                  <Select
                    multiple
                    native
                    size="1"
                    value={store.id}
                    onChange={changeStore}
                    inputProps={{
                      id: 'select-multiple-native',
                    }}>
                    {filterListStore.length > 0 &&
                      filterListStore.map((i) => (
                        <option key={i.guid} value={i.id}>
                          {i.domain}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="info-button">
                <Button className="info-save" variant="contained" onClick={save}>
                  Сохранить
                </Button>
                <Button className="info-save" variant="contained" onClick={handleClose}>
                  Отменить
                </Button>
              </div>
            </Typography>
          </Box>
        </div>
      </Modal>
    </>
  );
}
