import React, { useState, useEffect } from 'react';
import Network from './Requests';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../store/reducers/userSlice';
import { setListCompany, setCurrent } from '../store/reducers/companySlice';
import {
  setStore,
  setCurrentStore,
  setOpenAdd,
  setOpenChange,
  setIcon,
} from '../store/reducers/storeSlice';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import useInput from '../hooks/useInput';
import axios from 'axios';
import { globalConfig } from '../configuration/config';
import noImage from '../images/no-pictures.png';
import Dropzone from './Dropzone';

export default function Store(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.current);
  const companyList = useSelector((state) => state.company.companies);
  const companyCurrent = useSelector((state) => state.company.current);
  const store = useSelector((state) => state.store.list);
  const product = useSelector((state) => state.product.list);
  const storeCurrent = useSelector((state) => state.store.current);
  const storeInput = useInput();
  const [filename, setFilename] = useState('');

  const changeCompany = (e) => {
    dispatch(setCurrent(e.target.value));
    localStorage.setItem('company', JSON.stringify({ id: e.target.value }));
  };

  const changeStore = (id, guid) => {
    dispatch(setCurrentStore({ id: id, guid: guid }));
  };

  const openStoreAdd = () => {
    dispatch(setOpenAdd(true));
  };

  const delStore = async () => {
    await new Network().storeDelete(storeCurrent?.id);
    dispatch(setStore(store.filter((p) => p?.id !== storeCurrent?.id)));
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const contentType = 'application/octet-stream';

  const handleFileUpload = async (e) => {
    if (!e.target.files) {
      return;
    }

    const formData = new FormData();
    formData.append('metricsExcel', e.target.files[0]);

    let filename = e.target.files[0].name;
    let sliceName = filename.split('.').slice(0, -1).join('.');
    let fullName = sliceName + '_доп_данные.xlsx';

    const send = await new Network().FillYandexMetricsExcel(formData, fullName);
  };

  const changeImageStore = (iconPath) => {
    dispatch(setOpenChange(true));
    dispatch(setIcon(iconPath));
  };

  useEffect(() => {
    const fetch = async () => {
      if (companyCurrent) {
        const store = await new Network().GetStoresByCompany(companyCurrent);

        dispatch(setStore(store));
        dispatch(setCurrentStore({ id: store[0]?.id, guid: store[0]?.guid }));
      } else {
        dispatch(setStore([]));
        dispatch(setCurrentStore({ id: '', guid: '' }));
      }
    };

    fetch();
  }, [companyCurrent]);

  useEffect(() => {
    const fetch = async () => {
      const userInfo = await new Network().GetUsersInfo();
      const company = await new Network().GetUserCompanies();
      const companyCurrentServ = company.find((c) => c.isDefaultCompany == true);
      const companyLocal = JSON.parse(localStorage.getItem('company'))?.id;

      let insertCompany;

      if (companyLocal) {
        insertCompany = companyLocal;
      } else {
        insertCompany = companyCurrentServ?.id;
      }

      const store = await new Network().GetStoresByCompany(insertCompany);

      dispatch(setStore(store));
      dispatch(setCurrentStore({ id: store[0]?.id, guid: store[0]?.guid }));
      dispatch(setUser(userInfo));
      dispatch(setListCompany(company));
      dispatch(setCurrent(insertCompany));
    };

    fetch();
  }, []);

  const logout = async () => {
    await axios.get(`${globalConfig.config.common.identity}endsession`, {
      withCredentials: true,
    });
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <div className="wrap storeBlock">
      <Dropzone />
      <div className="user">
        <img src={user?.profilePictureFilePath} />
        {user?.name} {user?.lastName}
        <a className="exit" onClick={logout}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="1280.000000pt"
            height="1280.000000pt"
            viewBox="0 0 1280.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet">
            <g
              transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none">
              <path d="M6080 11255 c-171 -35 -306 -109 -435 -239 -100 -101 -158 -193 -199 -316 -36 -108 -46 -186 -46 -357 l0 -153 2355 0 c1694 0 2369 -3 2407 -11 167 -35 301 -170 337 -337 16 -76 16 -6808 0 -6884 -36 -167 -171 -302 -337 -337 -38 -8 -713 -11 -2407 -11 l-2355 0 0 -152 c0 -172 10 -250 46 -358 41 -122 99 -214 199 -316 133 -134 278 -211 459 -243 41 -8 807 -11 2393 -11 2569 0 2395 -4 2563 61 107 41 192 100 290 199 126 127 201 275 229 450 15 91 15 8229 0 8320 -55 348 -329 629 -677 696 -106 20 -4721 19 -4822 -1z" />
              <path d="M5825 8954 c-44 -14 -78 -43 -289 -252 -266 -265 -286 -292 -286 -397 0 -37 8 -76 19 -101 13 -28 204 -227 646 -670 l628 -629 -2574 -5 -2574 -5 -41 -22 c-56 -30 -119 -103 -133 -155 -9 -31 -11 -143 -9 -383 3 -328 4 -341 25 -381 25 -48 83 -101 129 -120 28 -12 446 -14 2604 -14 1413 0 2570 -3 2570 -7 0 -4 -281 -289 -625 -633 -478 -479 -629 -636 -644 -670 -27 -60 -27 -150 0 -210 29 -64 454 -491 523 -525 64 -32 157 -34 219 -5 31 15 363 341 1218 1197 1160 1162 1176 1179 1208 1251 30 66 33 80 30 150 -5 97 -35 161 -123 263 -35 41 -563 572 -1172 1181 -783 781 -1122 1112 -1153 1128 -57 29 -133 34 -196 14z" />
            </g>
          </svg>
        </a>
      </div>
      <div className="add-block">
        <div className="add-domain" onClick={openStoreAdd}>
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ControlPointIcon">
            <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </svg>
        </div>

        {product?.length == 0 && (
          <>
            {/* <div className="edit-domain">
              <EditIcon />
            </div> */}
            <div className="remove-domain" onClick={delStore}>
              <DeleteIcon />
            </div>
          </>
        )}
        <label htmlFor="upload-exel">
          <input
            style={{ display: 'none' }}
            id="upload-exel"
            name="upload-exel"
            multiple
            type="file"
            onChange={handleFileUpload}
          />
          <Button size="small" variant="outlined" component="span">
            Выбрать файл метрики
          </Button>{' '}
        </label>
      </div>
      <div className="company-select">
        <FormControl>
          <InputLabel htmlFor="age-native-simple">Компания</InputLabel>
          {companyCurrent && (
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={companyCurrent}
              onChange={changeCompany}
              label="Компания">
              {companyList?.map((c) => {
                return (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </FormControl>
      </div>
      <div className="store">
        <div className="info-inputs">
          <input type="text" placeholder="Поиск" {...storeInput} />
        </div>
        {store
          ? store
              ?.filter((item) => {
                return item?.domain?.toLowerCase().includes(storeInput.value.toLowerCase());
              })
              .map((c) => {
                return (
                  <div
                    className={'store--name ' + (c.guid == storeCurrent.guid ? 'active' : '')}
                    onClick={(e) => changeStore(c.id, c.guid)}
                    key={c.guid}>
                    {c?.iconPath == '' && (
                      <img src={noImage} alt="noImage" onClick={changeImageStore} />
                    )}
                    {c?.iconPath?.length > 0 && (
                      <span
                        style={{
                          backgroundImage: `url("${c.iconPath !== null && c?.iconPath}`,
                        }}
                        onClick={() => changeImageStore(c?.iconPath)}></span>
                    )}
                    <span>{c.domain}</span>
                  </div>
                );
              })
          : 'Доменов нет'}
      </div>
    </div>
  );
}
