import React, { useState, useEffect } from 'react';
import Network from './Requests';
import { useSelector, useDispatch } from 'react-redux';
import { setProductStore, setOpenStore } from '../store/reducers/productStoreSlice';
import { setOpenError } from '../store/reducers/errorSlice';
import { setOpenAdd, setItem, setSave } from '../store/reducers/productStoreAddSlice';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import { useConfirm } from '../hooks/useConfirm';

export default function ModalError(props) {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.error.open);
  const text = useSelector((state) => state.error.text);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowX: 'hidden',
  };

  const handleClose = () => {
    dispatch(setOpenError(false));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className="storeProduct-container">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              <div className="storeProduct--title">
                <span>Ошибка</span>
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="preformatted-text">{text}</div>
            </Typography>
          </Box>
        </div>
      </Modal>
    </>
  );
}
