import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import Main from '../Pages/Main';

const PageNotFound = () => <div>Page not found123</div>;

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/app" component={Main} />
    <Route component={PageNotFound} />
  </Switch>
);

export default Routes;
