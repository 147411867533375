import axios from 'axios';
import { globalConfig } from '../configuration/config';

const instance = axios.create({
  withCredentials: true,
});

export default class Network {
  constructor() {
    this.getUsersProject = this.getUsersProject.bind(this);
  }

  async GetUserCompanies() {
    const config = {};

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Companies/GetForUser`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetUserId(companyId) {
    const config = {
      params: {
        companyId: companyId,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}WorkplaceStructures/GetRootsForUser`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetOutputs(guidCalculation) {
    const config = {
      params: {
        guidCalculation: guidCalculation,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Calculation/GetOutputs`,
        config,
      );
      return response.data;
    } catch (error) {
      return { data: 'false' };
    }
  }

  async GetRootsWorkplaceStructuresForUser(companyId) {
    const config = {
      params: {
        IdCompany: companyId,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}WorkplaceStructures/GetRootsForUser`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetChildsWorkplaceStructuresForUser(idParent, companyId) {
    const config = {
      params: {
        IdCompany: companyId,
        IdParentStructure: idParent,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}WorkplaceStructureElements/GetChildsForUser`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetChildsWorkplaceStructuresForUserNew(
    IdParentStructure,
    guidGroup,
    searchText,
    guidLoad,
    id,
    timestamp,
    isMiddle,
    isUp,
    limit,
  ) {
    const config = {
      params: {
        IdParentStructure: IdParentStructure,
        guidGroup: guidGroup,
        searchText: searchText,
        guid: guidLoad,
        id: id,
        timestamp: timestamp,
        isMiddle: isMiddle,
        isUp: isUp,
        limit: limit,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/GetForUser`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getUsersProject(guid) {
    if (guid != undefined && guid != '') {
      const token = JSON.parse(
        localStorage.getItem(
          `oidc.user:${globalConfig.config.auth.authority}:${globalConfig.config.auth.client_id}`,
        ),
      ).access_token;

      const config = {
        headers: {},
        params: {
          projectGuid: guid,
        },
      };

      try {
        const response = await instance.get(
          `${globalConfig.config.common.server_api_endpoint}Projects/GetProjectUsers`,
          config,
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    }
  }

  async ShowOrHideChats(projectGuid, chatsEnabled) {
    const config = {
      params: {
        projectGuid: projectGuid,
        chatsEnabled: chatsEnabled,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/ShowOrHideChats`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async updateUser(name, lastName, patronymic, email, phone) {
    const body = {
      name: name,
      lastName: lastName,
      patronymic: patronymic,
      email: email,
      phone: phone,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Users/UpdateForUser`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async storeAdd(domain, idCompany) {
    const body = {
      domain: domain,
      name: domain,
      idCompany: idCompany,
    };

    const config = {};

    try {
      let a = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Store/Add`,
        body,
        config,
      );
      return a.data;
    } catch (error) {
      return {
        status: 'error',
        text: error?.response?.data,
      };
    }
  }

  async GetPublicLink(guidCalculation, type, idCompany) {
    const body = {
      guidCalculation: guidCalculation,
      type: type,
      idCompany: idCompany,
    };

    const config = {};

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/GetPublicLink`,
        body,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async FollowPublicLinkAnonymously(structureUrl, uniqueDeviceIdentifier) {
    const body = {
      structureUrl: structureUrl,
      uniqueDeviceIdentifier: uniqueDeviceIdentifier,
      clientId: 'web-business-app',
    };

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/FollowPublicLinkAnonymously`,
        body,
        // config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async FollowPublicLink(structureUrl, idCompany) {
    const body = {
      structureUrl: structureUrl,
      idCompany: idCompany,
    };

    const config = {};

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/FollowPublicLink`,
        body,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async ChangeConnectionInfo(companyId, guidProject, guidRoom) {
    const body = {
      companyId: companyId,
      guidProject: guidProject,
      guidRoom: guidRoom,
    };

    const config = {};

    try {
      await instance.get(
        `${globalConfig.config.common.base_url}/hubs/ChatHub/ChangeConnectionInfo`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async HandleUserInChats(chatsGuid, usersToAdd, usersToDelete) {
    const body = {
      chatsGuid: chatsGuid,
      usersToAdd: usersToAdd,
      usersToDelete: usersToDelete,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/HandleUserInChats`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async CreateProjectFromVariantsOfOtherProjects(
    projectGuids,
    name,
    orderNumber,
    address,
    customerFullName,
    phoneNumber,
    description,
    idCompany,
    amoCRMLink,
    source,
    productType,
    sum,
    expirationDate,
    requestDate,
    feedback,
  ) {
    const body = {
      projectGuids: projectGuids,
      project: {
        name: name,
        orderNumber: orderNumber,
        address: address,
        customerFullName: customerFullName,
        phoneNumber: phoneNumber,
        description: description,
        idCompany: idCompany,
        amoCRMLink: amoCRMLink,
        idSourceType: source,
        idProductType: productType,
        sum: sum,
        expirationDate: expirationDate,
        requestDate: requestDate,
        feedback: feedback,
      },
    };

    const config = {};

    try {
      const a = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/CreateProjectFromVariantsOfOtherProjects`,
        body,
        config,
      );
      return a.request.response;
    } catch (error) {
      console.log(error);
    }
  }

  async CreateProjectFromDisplayTapes(
    displayTapeGuids,
    name,
    orderNumber,
    address,
    customerFullName,
    phoneNumber,
    clientEmail,
    amoCRMLink,
    source,
    productType,
    sum,
    requestDate,
    orderDate,
    expirationDate,
    orderCompletionDate,
    archivedDate,
    feedback,
    description,
    workCosts,
    materialCosts,
    costsNote,
    idCompany,
  ) {
    const body = {
      displayTapeGuids: displayTapeGuids,
      project: {
        name: name,
        orderNumber: orderNumber,
        address: address,
        customerFullName: customerFullName,
        phoneNumber: phoneNumber,
        clientEmail: clientEmail,
        description: description,
        idCompany: idCompany,
        amoCRMLink: amoCRMLink,
        idSourceType: source,
        idProductType: productType,
        sum: sum,
        requestDate: requestDate,
        orderDate: orderDate,
        expirationDate: expirationDate,
        orderCompletionDate: orderCompletionDate,
        archivedDate: archivedDate,
        feedback: feedback,
        workCosts: workCosts,
        materialCosts: materialCosts,
        costsNote: costsNote,
      },
    };

    const config = {};

    try {
      const a = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/CreateProjectFromDisplayTapes`,
        body,
        config,
      );
      return a.request.response;
    } catch (error) {
      console.log(error);
    }
  }

  async updateAvatarUser(userGuid, formData) {
    const config = {};

    try {
      const a = await axios({
        url: `${globalConfig.config.common.server_api_endpoint}Users/AddOrUpdateUserProfilePicture?userGuid=${userGuid}`,
        method: 'POST',
        data: formData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
      return a.request.response;
    } catch (error) {
      console.log(error);
    }
  }

  async UpdateMessage(guidMessage, content) {
    const body = {
      guidMessage: guidMessage,
      content: content,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}ChatMessages/UpdateMessage`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async AddVariantsFromDisplayTapes(displayTapeGuids, projectGuid) {
    const body = {
      displayTapeGuids: displayTapeGuids,
      projectGuid: projectGuid,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/AddVariantsFromDisplayTapes`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async SendMessageWithFiles(formData) {
    const config = {};

    try {
      const a = await axios({
        url: `${globalConfig.config.common.server_api_endpoint}ChatMessages/SendMessageWithFiles`,
        method: 'POST',
        data: formData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
      return a.request.response;
    } catch (error) {
      console.log(error);
    }
  }

  async getHistoryChat(chatGuid, limit, firstMessageGuid) {
    const config = {
      params: {
        chatGuid: chatGuid,
        limit: limit,
        firstMessageGuid: firstMessageGuid,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}ChatMessages/GetMessagesByProjectGuid`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async Download(path) {
    const config = {
      params: {
        pathToFile: path,
      },
      responseType: 'arraybuffer',
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Data/DownloadFile`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetUserGuid() {
    const config = {};

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Users/GetUserGuid`,
        {
          withCredentials: true,
        },
      );
      return await response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetAllActive() {
    const config = {};

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Companies/GetAllActive`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async SendMessage(guidUser, guidChat, content, typeUser, guidCalculation, guidProject) {
    const body = {
      guidUser: guidUser,
      guidChat: guidChat,
      content: content,
      type: typeUser,
      guidCalculation: guidCalculation,
      guidProject: guidProject,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}ChatMessages/SendMessage`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async templateUpdate(id, content, idCompany) {
    const body = {
      id: id,
      content: content,
      idCompany: idCompany,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}MessageTemplates/Update`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async templateDelete(id) {
    const config = {
      params: {
        id: id,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}MessageTemplates/Delete`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async templateSave(name, content, idCompany) {
    const body = {
      name: name,
      content: content,
      idCompany: idCompany,
    };

    const config = {};

    try {
      const a = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}MessageTemplates/Add`,
        body,
        config,
      );
      return a.data;
    } catch (error) {
      console.log(error);
    }
  }

  async IncrementCounter(guidProject, type, guidUser, content, typeUser, guidCalculation) {
    //const token = JSON.parse(localStorage.getItem(`oidc.user:${globalConfig.config.auth.authority}:${globalConfig.config.auth.client_id}`)).access_token;

    const body = {
      guidProject: guidProject,
      type: type,
      guidUser: guidUser,
    };

    const config = {
      // headers: {
      //     Authorization: `Bearer ${token}`
      // }
    };

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/IncrementCounter`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async GetUsersInfo() {
    const config = {
      params: {},
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Users/GetUserInfo`,
        { withCredentials: true },
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetVariants(projectGuid) {
    const config = {
      params: {
        projectGuid: projectGuid,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/GetVariants`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetVariantss(guidProject, guidDisplayTape, flag, isUp, guidCalculation, limit) {
    const config = {
      params: {
        guidProject: guidProject,
        guidDisplayTape: guidDisplayTape,
        flag: flag,
        isUp: isUp,
        guidCalculation: guidCalculation,
        limit: limit,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}ProjectVariants/GetVariants`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetWorkplaceStructuresForCompany(IdCompany, IdParent) {
    const config = {
      params: {
        IdCompany: IdCompany,
        IdParent: IdParent,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}WorkplaceStructures/GetWorkplaceStructuresForCompany`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetCatalog(IdCompany, IdParent, isAllChats, idAccess) {
    const config = {
      params: {
        IdCompany: IdCompany,
        IdParent: IdParent,
        isAllChats: isAllChats,
        idAccess: idAccess,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}WorkplaceStructures/GetCatalog`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetForUserByProjectGuid(IdCompany, IdParent, isAllChats) {
    const config = {
      params: {
        IdCompany: IdCompany,
        IdParent: IdParent,
        isAllChats: isAllChats,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/GetForUserByProjectGuid`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetFileAzimuth(filePath) {
    const config = {
      params: {
        filePath: filePath,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Files/GetFileAzimuth`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetBaseProject(guid) {
    const config = {
      params: {
        guid: guid,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/GetBaseProject`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async InviteEmail(
    chatGuid,
    guidCalculation,
    recipient,
    userName,
    lifeTimeHours,
    type,
    actorNumber,
    messageContent,
  ) {
    const body = {
      chatGuid: chatGuid,
      guidCalculation: guidCalculation,
      recipient: recipient,
      userName: userName,
      lifeTimeHours: lifeTimeHours,
      type: type,
      actorNumber: actorNumber,
      messageContent: messageContent,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/SendLinkToEmail`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async OnlySendLinkToEmail(urlGuid, recipient) {
    const body = {
      urlGuid: urlGuid,
      recipient: recipient,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/OnlySendLinkToEmail`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async InviteViber(
    chatGuid,
    guidCalculation,
    recipient,
    userName,
    lifeTimeHours,
    type,
    actorNumber,
    messageContent,
  ) {
    const body = {
      chatGuid: chatGuid,
      guidCalculation: guidCalculation,
      recipient: recipient,
      userName: userName,
      lifeTimeHours: lifeTimeHours,
      type: type,
      actorNumber: actorNumber,
      messageContent: messageContent,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/SendLinkToViber`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async OnlySendLinkToViber(urlGuid, recipient) {
    const body = {
      urlGuid: urlGuid,
      recipient: recipient,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/OnlySendLinkToViber`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async InviteSms(
    chatGuid,
    guidCalculation,
    recipient,
    userName,
    lifeTimeHours,
    type,
    actorNumber,
    messageContent,
  ) {
    const body = {
      chatGuid: chatGuid,
      guidCalculation: guidCalculation,
      recipient: recipient,
      userName: userName,
      lifeTimeHours: lifeTimeHours,
      type: type,
      actorNumber: actorNumber,
      messageContent: messageContent,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/SendLinkToSMS`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async OnlySendLinkToSMS(urlGuid, recipient) {
    const body = {
      urlGuid: urlGuid,
      recipient: recipient,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/OnlySendLinkToSMS`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async AddUserInChats(chatsGuid, userGuid, companyId) {
    const body = {
      chatsGuid: chatsGuid,
      userGuid: userGuid,
      companyId: companyId,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/AddUserInChats`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async DeleteUserfromChats(chatsGuid, userGuid, companyId) {
    const body = {
      chatsGuid: chatsGuid,
      userGuid: userGuid,
      companyId: companyId,
    };

    const config = {};

    try {
      await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/DeleteUserfromChats`,
        body,
        config,
      );
    } catch (error) {
      console.log(error);
    }
  }

  async CreateQRCodeLink(chatGuid, guidCalculation, userName, lifeTimeHours, type, actorNumber) {
    const body = {
      chatGuid: chatGuid,
      guidCalculation: guidCalculation,
      userName: userName,
      lifeTimeHours: lifeTimeHours,
      type: type,
      actorNumber: actorNumber,
    };

    const config = {};

    try {
      const a = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/CreateQRCodeLink`,
        body,
        config,
      );
      return a.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetCompanyUsers(companyId, projectGuid) {
    const config = {
      params: {
        companyId: companyId,
        projectGuid: projectGuid,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Users/GetCompanyUsers`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetUserCatalog(guidProject, idCompany, idParent, idUser, type) {
    const config = {
      params: {
        guidProject: guidProject,
        idCompany: idCompany,
        idParent: idParent,
        idUser: idUser,
        type: type,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Companies/GetUserCatalog`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetUserCatalogWithoutLinkedUsers(idCompany, idParent, type) {
    const config = {
      params: {
        idCompany: idCompany,
        idParent: idParent,
        type: type,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Companies/GetUserCatalogWithoutLinkedUsers`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetGroups(idCompany) {
    const config = {
      params: {
        idCompany: idCompany,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Groups/GetGroups`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetGroupsByProjectGuidForUser(idCompany, guidProject) {
    const config = {
      params: {
        idCompany: idCompany,
        guidProject: guidProject,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Groups/GetGroupsByProjectGuidForUser`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetAllTransitions(idCompany) {
    const config = {
      params: {
        idCompany: idCompany,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Transitions/GetAllTransitions`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async DeleteTransition(id) {
    const config = {
      params: {
        id: id,
      },
    };

    try {
      const response = await axios.delete(
        `${globalConfig.config.common.server_api_endpoint}Transitions/DeleteTransition`,
        config,
      );

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async DeleteCRMGroupsForCompany(idCompany) {
    const config = {
      params: {
        idCompany: idCompany,
      },
    };

    const response = await axios.delete(
      `${globalConfig.config.common.server_api_endpoint}Groups/DeleteCRMGroupsForCompany`,
      config,
    );

    return response.data;
  }

  async AddTransition(guidFrom, guidTo) {
    const body = {
      guidGroupFrom: guidFrom,
      guidGroupTo: guidTo,
    };

    const config = {};

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Transitions/AddTransition`,
        body,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetByGuidGroup(guidGroup, idCompany) {
    const config = {
      params: {
        guidGroup: guidGroup,
        idCompany: idCompany,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/GetByGuidGroup`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetTagReference(idReferenceStructure, idCompany) {
    const config = {
      params: {
        idReferenceStructure: 100,
        idCompany: idCompany,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}ReferenceStructures/GetTagReference`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetCalculationsForUser(type, idCompany) {
    const config = {
      params: {
        tags: '#' + type,
        idCompany: idCompany,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Calculation/GetCalculationsForUser`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async JoinProjectByLink(url, clientId) {
    //const token = JSON.parse(localStorage.getItem(`oidc.user:${globalConfig.config.auth.authority}:${globalConfig.config.auth.client_id}`)).access_token;

    const config = {
      // headers: {
      //     Authorization: `Bearer ${token}`
      // },
      params: {
        url: url,
        clientId: clientId,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/JoinProjectByLink`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async addUserProject(
    name,
    description,
    idCompany,
    calculationGuid,
    chatsEnabled,
    orderNumber,
    address,
    customerFullName,
    phoneNumber,
    clientEmail,
    amoCRMLink,
    source,
    productType,
    sum,
    requestDate,
    orderDate,
    expirationDate,
    orderCompletionDate,
    archivedDate,
    feedback,
    workCosts,
    materialCosts,
    costsNote,
  ) {
    const body = {
      name: name,
      description: description,
      idCompany: idCompany,
      calculationGuid: calculationGuid,
      chatsEnabled: chatsEnabled,
      orderNumber: orderNumber,
      address: address,
      customerFullName: customerFullName,
      phoneNumber: phoneNumber,
      clientEmail: clientEmail,
      amoCRMLink: amoCRMLink,
      idSourceType: source,
      idProductType: productType,
      sum: sum,
      requestDate: requestDate,
      orderDate: orderDate,
      expirationDate: expirationDate,
      orderCompletionDate: orderCompletionDate,
      archivedDate: archivedDate,
      feedback: feedback,
      workCosts: workCosts,
      materialCosts: materialCosts,
      costsNote: costsNote,
    };

    const config = {};

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/AddForUser`,
        body,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async AddDisplayTape(guidCalculation, guidProject, guidUser, idCompany) {
    const body = {
      guidCalculation: guidCalculation,
      guidProject: guidProject,
      guidUser: guidUser,
      idCompany: idCompany,
    };

    const config = {};

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/AddDisplayTape`,
        body,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetSourceTypes() {
    const config = {
      params: {},
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/GetSourceTypes`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetGroup(guidGroup) {
    const config = {
      params: {
        guidGroup: guidGroup,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Groups/GetGroup`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetProductTypes() {
    const config = {
      params: {},
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/GetProductTypes`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async UpdateProjectsInGroup(guidsProjects, guidGroup) {
    const body = {
      guidsProjects: guidsProjects,
      guidGroup: guidGroup,
    };

    const config = {};

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Groups/UpdateProjectsInGroup`,
        body,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async UpdateGroup(guid, name, color, isCrm) {
    const body = {
      guid: guid,
      name: name,
      color: color,
      isCRM: isCrm,
    };

    const config = {};

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Groups/UpdateGroup`,
        body,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetProjectsToManageGroups(idCompany, guidGroup, inGroup, id, timestamp, limit) {
    const config = {
      params: {
        idCompany: idCompany,
        guidGroup: guidGroup,
        inGroup: inGroup,
        id: id,
        timestamp: timestamp,
        limit: limit,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/GetProjectsToManageGroups`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async AddGroup(name, guidUser, idCompany, isCRM, color) {
    const body = {
      name: name,
      guidUser: guidUser,
      idCompany: idCompany,
      isCRM: isCRM,
      color: color,
    };

    const config = {};

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Groups/AddGroup`,
        body,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async DeleteGroup(guid) {
    const config = {
      params: {
        guid: guid,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Groups/DeleteGroup`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async AddVariant(calculationGuid, projectGuid, calculationName) {
    const body = {
      calculationGuid: calculationGuid,
      projectGuid: projectGuid,
      calculationName: calculationName,
    };

    const config = {};

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/AddVariant`,
        body,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetFileUrlInCloud(pathToFile, maxSize) {
    const config = {
      params: {
        pathToFile: pathToFile,
        maxSize: maxSize,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Data/GetFileUrlInCloud`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetAllTemplate(idCompany) {
    const config = {
      params: {
        idCompany: idCompany,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}MessageTemplates/GetAll`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async DeleteProject(projectGuid) {
    const config = {
      params: {
        projectGuid: projectGuid,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/DeleteProject`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async DeleteVariant(calculationGuid) {
    const config = {
      params: {
        calculationGuid: calculationGuid,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Projects/DeleteVariant`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetUserStructureUrl(userGuid, chatGuid) {
    const config = {
      params: {
        userGuid: userGuid,
        chatGuid: chatGuid,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/GetUserStructureUrl`,
        config,
      );
      return response.data;
    } catch (error) {
      return {
        status: 'error',
        text: 'Вы не приглашали данного пользователя в этот проект',
      };
      //console.log(error);
    }
  }

  async ShareCalculationWithCompanies(calculationGuid, companyIds) {
    const body = {
      calculationGuid: calculationGuid,
      companyIds: companyIds,
    };

    const config = {};

    try {
      const a = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Calculation/ShareCalculationWithCompanies`,
        body,
        config,
      );
      return a.data;
    } catch (error) {
      console.log(error);
    }
  }

  async CopyProjectToCatalog(projectGuid) {
    const body = {
      projectGuid: projectGuid,
    };

    const config = {};

    try {
      const a = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/CopyProjectToCatalog`,
        body,
        config,
      );
      return a.data;
    } catch (error) {
      console.log(error);
    }
  }

  async Update(
    guidProject,
    name,
    orderNumber,
    address,
    customerFullName,
    phoneNumber,
    clientEmail,
    amoCRMLink,
    source,
    productType,
    sum,
    requestDate,
    orderDate,
    expirationDate,
    orderCompletionDate,
    archivedDate,
    feedback,
    description,
    workCosts,
    materialCosts,
    costsNote,
    guidGroup,
    idCompany,
  ) {
    const body = {
      guidProject: guidProject,
      name: name,
      orderNumber: orderNumber,
      address: address,
      customerFullName: customerFullName,
      phoneNumber: phoneNumber,
      clientEmail: clientEmail,
      amoCRMLink: amoCRMLink,
      idSourceType: source,
      idProductType: productType,
      sum: sum,
      requestDate: requestDate,
      orderDate: orderDate,
      expirationDate: expirationDate,
      orderCompletionDate: orderCompletionDate,
      archivedDate: archivedDate,
      feedback: feedback,
      description: description,
      workCosts: workCosts,
      materialCosts: materialCosts,
      costsNote: costsNote,
      guidGroup: guidGroup,
      idCompany: idCompany,
    };

    const config = {};

    try {
      const a = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Projects/Update`,
        body,
        config,
      );
      return a.data;
    } catch (error) {
      console.log(error);
    }
  }

  async UpdateStructureUrl(structureUrl, userName, lifeTimeHours) {
    const body = {
      structureUrl: structureUrl,
      userName: userName,
      lifeTimeHours: lifeTimeHours,
    };

    const config = {};

    try {
      const a = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}StructureUrls/UpdateStructureUrl`,
        body,
        config,
      );
      return a.data;
    } catch (error) {
      return {
        status: 'error',
      };
    }
  }

  async GetUnreadMessagesForUser(chatGuid, limit) {
    const config = {
      params: {
        chatGuid: chatGuid,
        limit: limit,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}ChatMessages/GetUnreadMessagesForUser`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async DownloadFileInChat(path, fileType, fileName) {
    axios({
      url: path,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log('res data: ', response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.${fileType}`);
      document.body.appendChild(link);
      link.click();
    });
  }

  async getCalculationInfo(guidCalculation) {
    const config = {
      params: {
        guidCalculation: guidCalculation,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Calculation/GetCalculationInfo`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetChatsExcel(intervalType, companyId, startDate, endDate, filename) {
    const body = {
      intervalType: intervalType,
      idCompany: companyId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    const config = {};

    try {
      axios({
        url: `${globalConfig.config.common.server_api_endpoint}Projects/GetChatsExcel`,
        method: 'POST',
        responseType: 'blob',
        data: body,
        config: config,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.log(error);
    }
  }

  async GetActiveCompanyUsers(companyId) {
    const config = {
      params: {
        companyId: companyId,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Users/GetActiveCompanyUsers`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async LoginByUserGuid(userGuid) {
    const config = {
      params: {
        guid: userGuid,
        clientId: globalConfig.config.auth.client_id,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Users/LoginByUserGuid`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetCalculationViews(guid) {
    const config = {
      params: {
        guid: guid,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Calculation/GetCalculationViews`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetStoresByCompany(idCompany, limit, offset) {
    const config = {
      idCompany: idCompany,
      limit: limit,
      offset: offset,
    };

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}Store/GetStoresByCompany`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async storeDelete(id) {
    const config = {
      params: {},
    };

    try {
      const response = await axios.delete(
        `${globalConfig.config.common.server_api_endpoint}Store/Delete/${id}`,
        { withCredentials: true },
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetByStore(storeId, onlyArchived, limit, offset) {
    const config = {
      params: {
        storeId: storeId,
        limit: limit,
        offset: offset,
        onlyArchived: onlyArchived,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}CalculationExternalProductCard/GetByStore`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetByStorePost(
    storeId,
    onlyArchived,
    fieldName,
    sortingDirection,
    limit,
    offset,
    searchKeyWord,
  ) {
    const config = {
      // headers: {

      // },

      storeId: storeId,
      onlyArchived: onlyArchived,
      pagination: {
        offset: offset,
        limit: limit,
      },
      sorting: {
        fieldName: fieldName,
        sortingDirection: sortingDirection,
      },
      filters: {
        searchKeyWord: searchKeyWord,
      },
    };

    try {
      const response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}CalculationExternalProductCard/GetByStore`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetSkyboxes(limit, offset) {
    const config = {
      params: {
        offset: offset,
        limit: limit,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}ProductCardSkybox/GetSkyboxes`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async CheckMainIconContainedInCalculation(calculationGuid) {
    const config = {
      params: {
        calculationGuid: calculationGuid,
      },
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}Calculation/CheckMainIconContainedInCalculation`,
        config,
        { withCredentials: true },
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }

  async Get(tokenProd) {
    const config = {
      params: {},
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}CalculationExternalProductCard/Get/` +
          tokenProd,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async GetLightPresets() {
    const config = {
      params: {},
    };

    try {
      const response = await instance.get(
        `${globalConfig.config.common.server_api_endpoint}CalculationExternalProductCard/GetLightPresets`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async changeIcon(obj, storeId) {
    try {
      const response = await instance({
        method: 'put',
        url: `${globalConfig.config.common.server_api_endpoint}Store/ChangeStoreIcon`,
        data: obj,
        params: { storeId: storeId },
      });
      return response.data;
    } catch (error) {
      return {
        status: 'error',
        text: error?.response?.data,
      };
    }
  }

  async UpdateProduct(obj) {
    try {
      const response = await instance({
        method: 'put',
        url: `${globalConfig.config.common.server_api_endpoint}CalculationExternalProductCard/Update`,
        data: obj,
        headers: {},
      });
      return response.data;
    } catch (error) {
      return {
        status: 'error',
        text: error?.response?.data,
      };
    }
  }

  async addProduct(obj) {
    const body = obj;
    const config = {};

    try {
      const res = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}CalculationExternalProductCard/Add`,
        body,
        config,
      );
      return res.data;
    } catch (error) {
      return {
        status: 'error',
        text: error?.response?.data,
      };
      // console.log(error);
    }
  }

  async DeleteSkybox(id) {
    const config = {
      params: {
        id: id,
      },
    };

    try {
      const response = await axios.delete(
        `${globalConfig.config.common.server_api_endpoint}ProductCardSkybox/DeleteSkybox`,
        config,
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async AddSkybox(formData) {
    const body = {};

    const config = {};

    try {
      let response = await instance.post(
        `${globalConfig.config.common.server_api_endpoint}ProductCardSkybox/AddSkybox`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return response.data;
    } catch (error) {
      return {
        status: 'error',
        text: error?.response?.data,
      };
    }
  }

  async FillYandexMetricsExcel(formData, filename) {
    const config = {};

    try {
      axios({
        url: `${globalConfig.config.common.server_api_endpoint}CalculationExternalProductCard/FillYandexMetricsExcel`,
        method: 'POST',
        responseType: 'blob',
        data: formData,
        config: config,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.log(error);
    }
  }

  async UpdateSkybox(obj) {
    try {
      const response = await axios({
        method: 'put',
        url: `${globalConfig.config.common.server_api_endpoint}ProductCardSkybox/UpdateSkybox`,
        data: obj,
        headers: {},
      });
      return response.data;
    } catch (error) {
      return {
        status: 'error',
        text: error?.response?.data,
      };
    }
  }

  async DeleteProd(id) {
    const config = {};

    try {
      const response = await axios.delete(
        `${globalConfig.config.common.server_api_endpoint}CalculationExternalProductCard/Delete/${id}`,
        { withCredentials: true },
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}
