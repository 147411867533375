import React, { useCallback, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

export default function useConfirmationDialog({
  headerText,
  bodyText,
  confirmationButtonText,
  onConfirmClick,
}) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowX: 'hidden',
    overflowY: 'scroll',
  };

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = (open) => {
    setIsOpen(open);
  };

  const Dialog = useCallback(
    () => (
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className="storeProduct-container">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              <div className="storeProduct--title">
                <span>{headerText}</span>
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {bodyText}
            </Typography>
            <div className="info-button">
              <Button className="info-save" variant="contained" onClick={onConfirmClick}>
                {confirmationButtonText}
              </Button>
              <Button
                className="info-save"
                variant="contained"
                onClick={() => {
                  setIsOpen(false);
                }}>
                Нет
              </Button>
            </div>
          </Box>
        </div>
      </Modal>
    ),
    [isOpen],
  );

  return {
    Dialog,
    onOpen,
  };
}
